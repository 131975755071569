import {
	FieldInterface,
	IAdditionalInfo,
	IAdditionalInfoValues,
	ICheckboxesInfo,
	ICheckboxesInfoValues,
	IConnexionInfo,
	IConnexionInfoValues,
	IContactInfo,
	IContactUSCheckboxesInfo,
	IContactUsCheckboxesInfoValues,
	IContactUsFormResult,
	ILoginInfo,
	ILoginInfoValues,
	INewClubFormResult,
	INewClubInfo,
	IOrganizationInfo,
	IOrganizationInfoValues,
	IProfileInfo,
	IProfileInfoValues,
	IProfilePasswords,
	IProfilePasswordsValues,
	IRegisterInfo,
	IRegisterInfoValues,
	IResetPasswordInfo,
	IResetPasswordInfoValues,
	IUserInfo,
	IUserInfoValues,
} from '@/partials/sponsorshipForm/logic/entities';
import {
	validateAmount,
	validateDate,
	validateDateMajor,
	validateEmail,
	validatePassword,
	validateRequired,
	validateSame,
	validateSelection,
	validateSiren,
	validateTrue,
} from '@/partials/sponsorshipForm/logic/validations';
import React from 'react';
import { countriesData } from '@/partials/sponsorshipForm/logic/countries';

const eighteenYearsAgo = () => {
	const now = new Date();
	now.setFullYear(now.getFullYear() - 18);
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, '0'); // Les mois sont de 0 à 11, donc ajoutez 1 et formatez
	const day = String(now.getDate()).padStart(2, '0'); // Formatez le jour avec un zéro initial si nécessaire
	return `${year}-${month}-${day}`;
};

const additionalInfo = (defaultValues: IAdditionalInfoValues): IAdditionalInfo => ({
	estOrganisme: {
		name: 'estOrganisme',
		type: 'hidden',
		isRequired: true,
		value: Number(Boolean(defaultValues.estOrganisme)),
		cast: Boolean,
	},
	montant: {
		label: 'Montant',
		name: 'montant',
		type: 'hidden',
		isRequired: true,
		value: String(defaultValues.montant || ''),
		validation: validateAmount,
		forceError: true,
		cast: Number,
	},
});

const organizationInfo = (defaultValues: IOrganizationInfoValues): IOrganizationInfo => ({
	socialReason: {
		name: 'socialReason',
		type: 'text',
		defaultValue: String(defaultValues.socialReason),
		isRequired: true,
		label: 'Raison sociale',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: 'KLUBR',
	},
	siren: {
		name: 'siren',
		type: 'text',
		defaultValue: String(defaultValues.siren),
		isRequired: true,
		label: 'Siren',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateSiren(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: '123 456 789',
	},
	legalForm: {
		name: 'legalForm',
		type: 'text',
		defaultValue: String(defaultValues.legalForm),
		isRequired: true,
		label: 'Forme juridique',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: 'SARL',
	},
	logo: {
		name: 'logo',
		type: 'hidden',
		defaultValue: String(defaultValues.logo),
		isRequired: false,
		label: 'logo pro',
		cast: String,
		parentClassName: 'hidden',
	},
});

const userInfo = (defaultValues: IUserInfoValues, isConnected?: boolean): IUserInfo => ({
	email: {
		name: 'email',
		type: 'email',
		disabled: isConnected,
		defaultValue: String(defaultValues.email),
		isRequired: true,
		label: 'Email',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateEmail(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'jean.lefebvre@email.fr',
	},
	civility: {
		name: 'civility',
		type: 'text',
		defaultValue: String(defaultValues.civility),
		isRequired: true,
		label: 'Civilité',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: 'Monsieur',
		extraType: 'select',
		options: ['Monsieur', 'Madame'],
		isReadonly: true,
	},
	firstName: {
		name: 'firstName',
		type: 'text',
		defaultValue: String(defaultValues.firstName),
		isRequired: true,
		label: 'Prénom',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: 'Jean',
	},
	lastName: {
		name: 'lastName',
		type: 'text',
		defaultValue: String(defaultValues.lastName),
		isRequired: true,
		label: 'Nom',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: 'LEFEBVRE',
	},
	birthdate: {
		name: 'birthdate',
		type: 'date',
		max: eighteenYearsAgo(),
		min: '1901-01-01',
		defaultValue: String(defaultValues.birthdate),
		isRequired: true,
		label: 'Date de naissance',
		// onKeyDown: (e) => e.preventDefault(),
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) ||
			validateDate(arg0, fieldName) ||
			validateDateMajor(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: '10/10/1989',
	},
	tel: {
		name: 'tel',
		type: 'text',
		defaultValue: String(defaultValues.tel),
		isRequired: false,
		label: 'Numéro de téléphone',
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: '+330700000000',
	},
	streetNumber: {
		name: 'streetNumber',
		type: 'text',
		defaultValue: String(defaultValues.streetNumber),
		isRequired: true,
		label: 'Numéro de rue',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31.1%] max-[767px]:w-full',
		placeholder: '08',
	},
	streetName: {
		name: 'streetName',
		type: 'text',
		defaultValue: String(defaultValues.streetName),
		isRequired: true,
		label: 'Nom de rue',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[65.3%] max-[767px]:w-full',
		placeholder: 'rue Victor Hugo',
	},
	postalCode: {
		name: 'postalCode',
		type: 'text',
		defaultValue: String(defaultValues.postalCode),
		isRequired: true,
		label: 'Code postal',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: '59800',
	},
	city: {
		name: 'city',
		type: 'text',
		defaultValue: String(defaultValues.city),
		isRequired: true,
		label: 'Ville',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: 'Lille',
	},
	country: {
		isRequired: true,
		label: 'Pays',
		name: 'country',
		type: 'text',
		defaultValue: String(defaultValues.country),
		validation: validateRequired,
		cast: String,
		parentClassName: 'w-[31%] max-[767px]:w-full',
		placeholder: 'France',
		extraType: 'select',
		countriesList: countriesData,
	},
});

const checkboxesInfo = (
	defaultValues: ICheckboxesInfoValues,
	cguLabel: React.ReactElement,
): ICheckboxesInfo => ({
	displayName: {
		label: 'Je veux que mon nom apparaisse dans la liste des donateurs sur la page ',
		name: 'displayName',
		type: 'checkbox',
		defaultChecked: Boolean(defaultValues.displayName),
		value: 1,
		cast: Boolean,
	},
	displayAmount: {
		label: 'Je souhaite afficher le montant de mon don',
		name: 'displayAmount',
		type: defaultValues.displayName ? 'checkbox' : 'hidden',
		defaultChecked: defaultValues.displayName ? Boolean(defaultValues.displayAmount) : false,
		defaultValue: defaultValues.displayName ? Number(defaultValues.displayAmount) : 0,
		cast: Boolean,
	},
	acceptConditions1: {
		name: 'acceptConditions1',
		// label: "J'ai compris que Klubr est une entreprise sociale et solidaire.",
		label: "J'ai bien compris que Klubr est un fonds de dotation redistributeur.",
		// TODO: " et confirme vouloir lui apporter une contribution financière, facultative et modifiable, en finalisant mon paiement pour assurer la gratuité du service à l'association."
		type: 'checkbox',
		isRequired: true,
		defaultChecked: Boolean(defaultValues.acceptConditions1),
		validation: validateTrue,
		cast: Boolean,
	},
	acceptConditions2: {
		name: 'acceptConditions2',
		label: cguLabel,
		type: 'checkbox',
		isRequired: true,
		defaultChecked: Boolean(defaultValues.acceptConditions2),
		validation: validateTrue,
		cast: Boolean,
	},
});

const contactUsInfo = (defaultValues: IContactUsFormResult, email?: string): IContactInfo => ({
	email: {
		name: 'email',
		type: 'email',
		defaultValue: String(defaultValues.email),
		isRequired: true,
		label: 'Adresse e-mail',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateEmail(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'jean.lefebvre@email.fr',
		disabled: !!email,
	},
	object: {
		name: 'object',
		type: 'text',
		defaultValue: String(defaultValues.object),
		isRequired: true,
		label: 'Objet',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'Objet',
	},
	msg: {
		name: 'msg',
		type: 'text',
		extraType: 'textarea',
		defaultValue: String(defaultValues.msg),
		isRequired: true,
		label: 'Message',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'Votre message',
	},
});

const sportsList = [
	'Autre',
	'Aïkido',
	'Aquatique',
	'Athlétisme',
	'Aviron',
	'Badminton',
	'Baseball',
	'Basket-ball',
	'Billard',
	'Boxe',
	'Canöe',
	'Char à voile',
	'Course à pied',
	'Danse',
	'Equitation',
	'Escalade',
	'Escrime',
	'Fléchettes',
	'Football',
	'Golf',
	'Gymnastique',
	'Handball',
	'Hockey',
	'Hockey sur glace',
	'Judo',
	'Karaté',
	'Lutte',
	'Moto',
	'Natation',
	'Nautisme',
	'Patinage',
	'Pêche',
	'Pétanque',
	'Planche à voile',
	'Plongée sous marine',
	'Ski alpin',
	'Ski de fond',
	'Ski nautique',
	'Spéléologie',
	'Sports nautiques',
	'Sports automobiles',
	'Surf',
	'Taekwondo',
	'Tennis',
	'Tennis de table',
	"Tir à l'arc",
	'Triathlon',
	'Vélo/BMX/VTT',
	'Voile',
	'Volleyball',
	'Waterpolo',
	'Trail',
];

const legalStatusList = [
	'Association Loi 1901',
	'Collectivités territoriales',
	'Fondation',
	'Fonds de dotation',
];

const newClubInfo = (
	defaultValues: INewClubFormResult,
	cguLabel: React.ReactElement,
): INewClubInfo => ({
	nom: {
		name: 'nom',
		type: 'text',
		defaultValue: defaultValues.nom,
		isRequired: true,
		label: 'Nom du dirigeant',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'md:w-[48.25%] w-full',
		placeholder: 'Nom',
		autoComplete: 'off',
	},
	prenom: {
		name: 'prenom',
		type: 'text',
		defaultValue: defaultValues.prenom,
		isRequired: true,
		label: 'Prénom',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'md:w-[48.25%] w-full',
		placeholder: 'Prénom',
		autoComplete: 'off',
	},
	birthDate: {
		name: 'birthDate',
		type: 'date',
		max: eighteenYearsAgo(),
		min: '1901-01-01',
		defaultValue: String(defaultValues.birthDate),
		isRequired: true,
		label: 'Date de naissance',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) ||
			validateDate(arg0, fieldName) ||
			validateDateMajor(arg0, fieldName),
		cast: String,
		parentClassName: 'md:w-[48.25%] w-full',
		placeholder: '10/10/1989',
		autoComplete: 'off',
	},
	tel: {
		name: 'tel',
		type: 'tel',
		defaultValue: defaultValues.tel,
		isRequired: true,
		label: 'Numéro du téléphone',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'md:w-[48.25%] w-full',
		placeholder: '+33 XX XX XX XX XX',
		autoComplete: 'off',
	},
	email: {
		name: 'email',
		type: 'email',
		defaultValue: String(defaultValues.email),
		isRequired: true,
		label: 'Adresse e-mail du dirigeant',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateEmail(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'Adresse email',
		autoComplete: 'off',
	},
	clubName: {
		name: 'clubName',
		type: 'text',
		defaultValue: defaultValues.clubName,
		isRequired: true,
		label: 'Nom du club',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'md:w-[48.25%] w-full',
		placeholder: 'Nom du club',
		autoComplete: 'off',
	},
	acronyme: {
		name: 'acronyme',
		type: 'text',
		defaultValue: defaultValues.acronyme,
		isRequired: true,
		label: 'Sigle',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'md:w-[48.25%] w-full',
		placeholder: 'FCB, ASM...',
		autoComplete: 'off',
	},
	adresse: {
		name: 'adresse',
		type: 'text',
		defaultValue: defaultValues.adresse,
		isRequired: true,
		label: 'Adresse du club',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'Adresse du club',
		extraType: 'places',
		autoComplete: 'off',
	},
	legalStatus: {
		name: 'legalStatus',
		type: 'text',
		defaultValue: String(defaultValues.legalStatus),
		isRequired: true,
		label: 'Structure juridique',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateSelection(arg0, fieldName, legalStatusList),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'Fondation',
		extraType: 'select',
		options: legalStatusList,
	},
	sportType: {
		name: 'sportType',
		type: 'text',
		defaultValue: String(defaultValues.sportType),
		isRequired: true,
		label: 'Type de sport',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateSelection(arg0, fieldName, sportsList),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'Football',
		extraType: 'select',
		options: sportsList,
	},
	acceptConditions1: {
		name: 'acceptConditions1',
		label:
			"Je confirme agir en tant que représentant de l'organisme et créer le compte d’une association ou d’un club domicilié en France.",
		type: 'checkbox',
		isRequired: true,
		defaultChecked: Boolean(defaultValues.acceptConditions1),
		validation: validateTrue,
		cast: Boolean,
		parentClassName: 'w-full',
	},
	acceptConditions2: {
		name: 'acceptConditions2',
		label: cguLabel,
		type: 'checkbox',
		isRequired: true,
		defaultChecked: Boolean(defaultValues.acceptConditions2),
		validation: validateTrue,
		cast: Boolean,
		parentClassName: 'w-full',
	},
});

const contactUsCheckboxes = (
	defaultValues: IContactUsCheckboxesInfoValues,
): IContactUSCheckboxesInfo => ({
	acceptConditions1: {
		name: 'acceptConditions1',
		label: "J'accepte les Conditions Générales d’Utilisation*",
		type: 'checkbox',
		isRequired: true,
		defaultChecked: Boolean(defaultValues.acceptConditions1),
		validation: validateTrue,
		cast: Boolean,
	},
	acceptConditions2: {
		name: 'acceptConditions2',
		label: "J'accepte de recevoir des informations sur Klubr par email",
		type: 'checkbox',
		isRequired: true,
		defaultChecked: Boolean(defaultValues.acceptConditions2),
		validation: validateTrue,
		cast: Boolean,
	},
});

const connexionInfo = (
	defaultValues: IConnexionInfoValues,
	isDisabled?: boolean,
): IConnexionInfo => ({
	identifier: {
		name: 'identifier',
		type: 'email',
		defaultValue: String(defaultValues.identifier),
		isRequired: true,
		label: 'Adresse E-mail',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateEmail(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'jean.lefebvre@email.fr',
		disabled: isDisabled,
	},
});
const loginInfo = (defaultValues: ILoginInfoValues, hiddenEmail?: boolean): ILoginInfo => ({
	identifier: {
		name: 'identifier',
		type: hiddenEmail ? 'hidden' : 'email',
		defaultValue: String(defaultValues.identifier),
		isRequired: true,
		label: 'Adresse E-mail',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateEmail(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'jean.lefebvre@email.fr',
	},
	password: {
		name: 'password',
		type: 'password',
		defaultValue: String(defaultValues.password),
		isRequired: true,
		label: 'Mot de passe',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: '',
	},
});

const resetPasswordInfo = (defaultValues: IResetPasswordInfoValues): IResetPasswordInfo => ({
	password: {
		name: 'password',
		type: 'password',
		defaultValue: String(defaultValues.password),
		isRequired: true,
		label: 'Mot de passe',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) ||
			validatePassword(arg0, fieldName) ||
			validateSame(arg0, fieldName, String(defaultValues.passwordConfirmation)),
		cast: String,
		parentClassName: 'w-full',
		placeholder: '',
	},
	passwordConfirmation: {
		name: 'passwordConfirmation',
		type: 'password',
		defaultValue: String(defaultValues.passwordConfirmation),
		isRequired: true,
		label: 'Confirmez votre Mot de passe',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) ||
			validateSame(arg0, fieldName, String(defaultValues.password)),
		cast: String,
		parentClassName: 'w-full',
		placeholder: '',
	},
});

const profileInfo = (defaultValues: IProfileInfoValues): IProfileInfo => ({
	identifier: {
		name: 'identifier',
		type: 'email',
		defaultValue: String(defaultValues.identifier),
		isRequired: true,
		label: 'Adresse E-mail',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateEmail(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'jean.lefebvre@email.fr',
		disabled: true,
	},
	nom: {
		name: 'nom',
		type: 'text',
		defaultValue: String(defaultValues.nom),
		isRequired: true,
		label: 'Nom et prénom',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'Jean Lefebvre',
	},
});

const profilePasswords = (defaultValues: IProfilePasswordsValues): IProfilePasswords => ({
	currentPassword: {
		name: 'currentPassword',
		type: 'password',
		defaultValue: '',
		isRequired: true,
		label: 'Mot de passe actuel',
		validation: (arg0, fieldName) => validateRequired(arg0, fieldName),
		cast: String,
		parentClassName: 'w-[47%] max-[767px]:w-full',
		placeholder: '',
	},
	password: {
		name: 'password',
		type: 'password',
		defaultValue: '',
		isRequired: true,
		label: 'Nouveau mot de passe',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) ||
			validatePassword(arg0, fieldName) ||
			validateSame(arg0, fieldName, String(defaultValues.passwordConfirmation)),
		cast: String,
		parentClassName: 'w-[47%] max-[767px]:w-full',
		placeholder: '',
	},
	passwordConfirmation: {
		name: 'passwordConfirmation',
		type: 'password',
		defaultValue: '',
		isRequired: true,
		label: 'Confirmer le nouveau mot de passe',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) ||
			validateSame(arg0, fieldName, String(defaultValues.password)),
		cast: String,
		parentClassName: 'w-[47%] max-[767px]:w-full',
		placeholder: '',
	},
});

const registerInfo = (
	defaultValues: IRegisterInfoValues,
	cguLabel: React.ReactElement,
	hiddenEmail?: boolean,
): IRegisterInfo => ({
	email: {
		name: 'email',
		type: hiddenEmail ? 'hidden' : 'email',
		defaultValue: String(defaultValues.email),
		isRequired: true,
		label: 'Adresse E-mail',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) || validateEmail(arg0, fieldName),
		cast: String,
		parentClassName: 'w-full',
		placeholder: 'jean.lefebvre@email.fr',
	},
	password: {
		name: 'password',
		type: 'password',
		defaultValue: String(defaultValues.password),
		isRequired: true,
		label: 'Mot de passe',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) ||
			validatePassword(arg0, fieldName) ||
			validateSame(arg0, fieldName, String(defaultValues.confirmPassword)),
		cast: String,
		parentClassName: 'w-full',
		placeholder: '',
	},
	confirmPassword: {
		name: 'confirmPassword',
		type: 'password',
		defaultValue: String(defaultValues.confirmPassword),
		isRequired: true,
		label: 'Confirmez votre Mot de passe',
		validation: (arg0, fieldName) =>
			validateRequired(arg0, fieldName) ||
			validateSame(arg0, fieldName, String(defaultValues.password)),
		cast: String,
		parentClassName: 'w-full',
		placeholder: '',
	},
	acceptConditions: {
		name: 'acceptConditions',
		label: cguLabel,
		type: 'checkbox',
		isRequired: true,
		defaultChecked: Boolean(defaultValues.acceptConditions),
		validation: validateTrue,
		cast: Boolean,
	},
});

const previousStepButtonInfo = (index: number): FieldInterface => ({
	name: 'previous',
	value: 'Étape précédente',
	type: 'button',
	className: index === 0 || index > 3 ? 'hidden' : 'buttonInput',
	cast: String,
	parentClassName: 'max-[767px]:w-full',
});

const submitInfo = (index: number): FieldInterface => ({
	name: 'submit',
	value: index === 2 ? 'Valider et payer' : 'Étape suivante',
	type: index <= 2 ? (index === 2 ? 'submit' : 'button') : 'hidden',
	cast: String,
	className: 'buttonInput h-[46.38px]',
	parentClassName: 'max-[767px]:w-full',
	extraType: 'spinner',
});

export {
	userInfo,
	loginInfo,
	submitInfo,
	newClubInfo,
	profileInfo,
	registerInfo,
	connexionInfo,
	contactUsInfo,
	additionalInfo,
	checkboxesInfo,
	profilePasswords,
	organizationInfo,
	resetPasswordInfo,
	contactUsCheckboxes,
	previousStepButtonInfo,
};
